import getBreadcrumb from '@/assets/js/shared/helpers/getBreadcrumb';

/**
 * Return a formatted SEO.Lastname breadcrumb list
 *
 * @param {object} opts
 * @param {string} opts.initialText
 * @param {string} opts.lastname
 * @returns {[{text: (string|string|*), href: string}]|*[]}
 */
const setLastnameCrumbs = (opts = {}) => {
  if (!opts || !opts.initialText || !opts.lastname) {
    return [];
  }
  // Initial breadcrumb
  let crumbs = [
    {
      text: opts.initialText,
      href: '/'
    }
  ];

  // Add people directory link
  crumbs.push(
    getBreadcrumb({
      text: opts.lastname,
      href: `/${opts.lastname}/`,
      disabled: true
    })
  );

  return crumbs;
};

export default setLastnameCrumbs;
