<template>
  <div class="fp-content">
    <tz-breadcrumbs v-once :items="breadcrumbs" :site-url="siteUrl" />
    <div class="fp-directory">
      <h1 class="fp-directory__title" v-text="`${lastname} in the US`" />
      <ul class="fp-directory__list">
        <li
          class="fp-directory__item"
          v-for="(firstname, index) in this.firstnames"
          :key="index"
          v-if="optouts && !optouts.includes(`${firstname} ${lastname}`)"
        >
          <a
            class="fp-directory__link"
            :href="`/${lastname}/${firstname}/`"
            v-text="`${firstname} ${lastname}`"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// Components
import TzBreadcrumbs from '@trazi/tz-breadcrumbs/src/tz-breadcrumbs.vue';
// Helpers
import queryWithSearchSDK from '@/assets/js/shared/helpers/queryWithSearchSDK';
import setLastnameCrumbs from '@/assets/js/helpers/setLastnameCrumbs';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import searchSDK from 'assets/js/shared/services/search-sdk';
import getOptOutNames from '@/assets/js/shared/helpers/getOptOutNames';
const NBars = require('nbars/commonjs.js');

const { LocalNames } = searchSDK;

export default {
  name: 'seo.lastname',
  components: {
    TzBreadcrumbs
  },
  computed: {
    siteUrl() {
      return `https://freepeoplesearch.com`;
    },
    breadcrumbs() {
      return setLastnameCrumbs({
        initialText: 'People Search',
        lastname: this.lastname
      });
    },
    lastname() {
      return setTitleCase({ text: this.$route.params.lastname });
    }
  },
  methods: {},
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: require(`@/assets/cms/pages/directory.json`)
        };
      }
    }
  },
  head() {
    const transformedMeta = [];

    for (let i = 0; i < this.cms.page.lastname.meta.length; i++) {
      const metaCopy = Object.assign({}, this.cms.page.lastname.meta[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'description':
        case 'og:description':
          metaCopy.content = NBars.transform(content, {
            lastName: this.lastname
          });
          break;
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            lastName: this.lastname
          });
          break;
        case 'keywords':
        case 'url':
        case 'og:url':
          metaCopy.content = NBars.transform(content, {
            lastName: this.lastname
          });
      }

      transformedMeta[i] = metaCopy;
    }

    return {
      title: NBars.transform(this.cms.page.lastname.page_title, {
        lastName: this.lastname
      }),
      meta: transformedMeta,
      link: [
        {
          rel: 'canonical',
          href: `${this.siteUrl}/${this.lastname}/`
        }
      ]
    };
  },
  async asyncData({ params, error }) {
    if (!process.server) {
      return;
    }
    const payload = {};

    try {
      payload.firstnames = [];

      payload.optouts = await getOptOutNames();

      const queryFirstnames = await queryWithSearchSDK({
        searchMethod: LocalNames.firstNames,
        queryParams: { lastName: params.lastname, limit: 1000 },
        defaultReturnValue: []
      });
      // TODO Can remove first condition once back end fixes bug with null in array
      if (
        (queryFirstnames.length === 1 && queryFirstnames[0] === null) ||
        queryFirstnames.length === 0
      ) {
        return error({ message: 'No data found', status: 404 });
      }

      // Format All Firstnames
      for (let i = 0; i < queryFirstnames.length; i++) {
        const formattedName = setTitleCase({ text: queryFirstnames[i] });
        payload.firstnames.push(formattedName);
      }

      return payload;
    } catch (e) {
      return error({ statusCode: 404 });
    }
  }
};
</script>
