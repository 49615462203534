import sdk from '@trazi/opt-out/sdk/node';
import bugsnag from '@bugsnag/js';

/**
 * Returns a formatted list of names from the opt-out SDK.
 * Names are often delimited by a space, dash, or pipe
 * @param {string} delimiter
 * @returns {array}
 */
const getOptOutNames = async (delimiter = ' ') => {
  const optOutSDK = sdk(`${process.env.BASE_URL}/api`);
  try {
    const response = await optOutSDK.LocalDomain.getIndexList(
      {},
      `${process.env.WEBSITE}.com`
    );

    // Fallback for if response fails to prevent entire page from crashing
    if (!Array.isArray(response)) {
      const website = process.env.WEBSITE
        ? process.env.WEBSITE.toUpperCase()
        : process.env.PREFIX;
      bugsnag.notify(
        `${website}: optOutSDK.LocalDomain.getIndexList Response Is Not An Array`
      );

      return [];
    }

    return response.map(
      name => `${name.firstname}${delimiter}${name.lastname}`
    );
  } catch (error) {
    console.log(error);
  }
};

export default getOptOutNames;
