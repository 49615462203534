/**
 * Return a single breadcrumb
 *
 * @param {object} opts
 * @param {string} opts.text
 * @param {boolean} opts.disabled
 * @param {string} opts.href
 * @param {string} opts.emoji
 * @param {string} opts.schemaName
 * @returns {{disabled: *, text: *, href: *, emoji: *, schemaName: *}}
 */
const getBreadcrumb = function(opts = {}) {
  return opts;
};

export default getBreadcrumb;
